<p-toast />

<div *ngIf="isStaging" class="w-full flex p-3 bg-yellow-100 text-center justify-content-center">
  <div class="mr-3">
    <div class="text-yellow-900 font-medium text-l">Staging environment</div>
  </div>
</div>

<ng-container *transloco="let t;">
  <div class="px-4 md:px-5 lg:px-6 surface-overlay relative">
    <div class="py-4 flex align-items-center justify-content-between lg:static">
      <img src="assets/images/ShopChat-logo.png" draggable="false" alt="Logo" height="40" class="mr-0 hidden md:block">
      <img src="assets/images/ShopChat-logo.png" draggable="false" alt="Logo" height="30" class="mr-0 block md:hidden">
  
      <div class="hidden lg:block absolute lg:static lg:w-auto w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
          <li>
            <a (click)="toggleFullScreen()"
              class="p-ripple p-element flex px-6 p-3 lg:px-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                {{ isFullScreen ? 'fullscreen_exit' : 'fullscreen' }}
              </span>
              <span class="block lg:hidden font-medium">Inbox</span>
            </a>
          </li>
          <li>
            <a (click)="langMenu.toggle($event)"
              class="p-ripple p-element flex px-6 p-3 lg:px-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <img [src]="'assets/flags/' + activeLang + '.png'" height="32" draggable="false" alt="Lang">
              <span class="block lg:hidden font-medium">Notifications</span>
            </a>
          </li>
          <li class="border-top-1 surface-border lg:border-top-none">
            <a (click)="menu.toggle($event)"
              class="relative p-ripple p-element flex px-6 p-3 lg:px-3 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <p-avatar *ngIf="avatarLabel" [label]="avatarLabel" size="normal"
                [style]="{ 'background-color': '#ece9fc', color: '#2a1261' }" shape="circle" />
              <img *ngIf="!avatarLabel" src="assets/images/user.png" width="28" class="mr-3 lg:mr-0">
            </a>
          </li>
        </ul>
        <p-menu #langMenu [model]="langOptions" [popup]="true"></p-menu>
        <p-menu #menu [model]="profileOptions" [popup]="true">
          <ng-template *ngIf="user" pTemplate="start">
            <div class="px-3 py-2 m-0">
              <b class="text-overflow-ellipsis overflow-hidden">{{ user.name | titlecase }} {{ user.surname | titlecase }}</b>
              <br>
              <p class="text-sm text-500 m-0 mt-2 text-overflow-ellipsis overflow-hidden">{{ user.email }}</p>
              <p-badge *ngIf="user.admin" styleClass="mt-2" [value]="'Account admin'" severity="warning" />
            </div>
            <p-divider styleClass="my-1" />
          </ng-template>
        </p-menu>
      </div>

      <!-- mobile menu icon -->
      <div class="block lg:hidden">
        <span (click)="sidebarVisible = true" class="material-symbols-sharp text-600 cursor-pointer">
          menu
        </span>
      </div>
    </div>
    <div class="custom-scrollbar py-3 hidden lg:block absolute lg:static lg:w-auto w-full surface-overlay left-0 top-100 z-1 border-top-1 surface-border overflow-x-auto white-space-nowrap">
      <div class="flex justify-content-between">
        <ul class="list-none p-0 m-0 flex lg:align-items-center flex-column lg:flex-row select-none">
          <li *ngIf="user?.admin">
            <a [routerLink]="'/admin/users'"
              [ngClass]="{'text-blue-500 surface-100': router.url == '/admin/users', 'hover:text-900 hover:surface-100 cursor-pointer': router.url != '/admin/users'}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 font-medium border-round transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                person
              </span>
              <span class="ml-2">Users</span>
              <span class="p-ink"></span>
            </a>
          </li>
          <li *ngIf="!user?.admin && !(assistants.length == maxAssistants && maxAssistants == 1)">
            <a (click)="changePage('/', undefined, false)"
              [ngClass]="{'text-blue-500 surface-100': router.url == '/', 'hover:text-900 hover:surface-100 cursor-pointer': router.url != '/'}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 font-medium border-round transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                smart_toy
              </span>
              <span class="ml-2">{{ t('chatbots.title') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
          <li *ngIf="urlContainsUuid()">
            <a (click)="changePage('dashboard')"
              [ngClass]="{'text-blue-500 surface-100': router.url.includes('/dashboard'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/dashboard')}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                home
              </span>
              <span class="ml-2">{{ t('dashboard.title') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
          <li *ngIf="urlContainsUuid()">
            <a (click)="changePage('chats')"
              [ngClass]="{'text-blue-500 surface-100': router.url.includes('/chats'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/chats')}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                chat
              </span>
              <span class="ml-2">{{ t('chats.title') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
          <li *ngIf="urlContainsUuid()">
            <a (click)="changePage('sources')"
              [ngClass]="{'text-blue-500 surface-100': router.url.includes('/sources'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/sources')}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                school
              </span>
              <span class="ml-2">{{ t('sources.title') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
          <li *ngIf="urlContainsUuid()">
            <a (click)="changePage('leads')"
              [ngClass]="{'text-blue-500 surface-100': router.url.includes('/leads'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/leads')}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                leaderboard
              </span>
              <span class="ml-2">{{ t('leads.title') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
          <li *ngIf="urlContainsUuid() && (userHasPluginAvailable || user?.admin)">
            <a (click)="changePage('plugins')"
              [ngClass]="{'text-blue-500 surface-100': router.url.includes('/plugins'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/plugins')}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                extension
              </span>
              <span class="ml-2">{{ t('plugins.title') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
          <li *ngIf="urlContainsUuid()">
            <a (click)="changePage('settings')"
              [ngClass]="{'text-blue-500 surface-100': router.url.includes('/settings'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/settings')}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                settings
              </span>
              <span class="ml-2">{{ t('settings.title') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
          <li *ngIf="urlContainsUuid()">
            <a (click)="changePage('embed')"
              [ngClass]="{'text-blue-500 surface-100': router.url.includes('/embed'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/embed')}"
              class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                link
              </span>
              <span class="ml-2">{{ t('embed_on_site.title') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
        </ul>
        <ul class="list-none p-0 m-0 flex lg:align-items-center flex-column lg:flex-row select-none">
          <li *ngIf="urlContainsUuid()">
            <a (click)="openChat()" target="_blank"
              class="hover:text-900 hover:surface-100 cursor-pointer no-underline p-ripple p-element flex p-3 align-items-center text-600 font-medium border-round transition-colors transition-duration-150">
              <span class="material-symbols-sharp">
                open_in_new
              </span>
              <span class="ml-2">{{ t('open_chat') }}</span>
              <span class="p-ink"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  
  <div *ngIf="selectedAssistant" class="content-page px-4 md:px-5 lg:px-6">
    <ul class="list-none pl-0 pt-3 pb-2 m-0 flex align-items-center font-medium gap-1">
      <li>
        <a (click)="router.navigate(['/'])" class="cursor-pointer text-blue-500">
          <span class="material-symbols-sharp">
            home
          </span>
        </a>
      </li>
      <li><span class="text-500">/</span></li>
      <li>
        <a class="text-600 white-space-nowrap">{{ t('chatbot') }}</a>
      </li>
      <li><span class="text-500">/</span></li>
      <li class="white-space-nowrap overflow-hidden text-overflow-ellipsis"><span class="text-600">{{ selectedAssistant.name }}</span></li>
    </ul>
  </div>

  <!-- mobile sidebar menu -->
  <p-sidebar [(visible)]="sidebarVisible" [blockScroll]="true">
    <ng-template pTemplate="headless">
      <div class="flex flex-column h-full">
        <div class="flex align-items-center justify-content-between py-4 px-4 flex-shrink-0">
          <span class="inline-flex align-items-center gap-2">
            <img src="assets/images/ShopChat-logo.png" draggable="false" alt="Logo" height="30" class="mr-0">
          </span>
          <span>
            <span (click)="sidebarVisible = false" class="material-symbols-sharp text-600 cursor-pointer">
              close
            </span>
          </span>
        </div>

        <div class="overflow-y-auto">
          <ul class="list-none p-3 m-0">
            <li *ngIf="user?.admin">
              <a [routerLink]="'/admin/users'"
                [ngClass]="{'text-blue-500 surface-100': router.url == '/admin/users', 'hover:text-900 hover:surface-100 cursor-pointer': router.url != '/admin/users'}"
                class="no-underline p-ripple p-element flex p-3 align-items-center text-600 font-medium border-round transition-colors transition-duration-150">
                <span class="material-symbols-sharp">
                  person
                </span>
                <span class="ml-2">Users</span>
                <span class="p-ink"></span>
              </a>
            </li>
            <li *ngIf="!user?.admin && !(assistants.length == maxAssistants && maxAssistants == 1)">
              <a (click)="changePage('/', undefined, false)"
                [ngClass]="{'text-blue-500 surface-100': router.url == '/', 'hover:text-900 hover:surface-100 cursor-pointer': router.url != '/'}"
                class="no-underline p-ripple p-element flex p-3 align-items-center text-600 font-medium border-round transition-colors transition-duration-150">
                <span class="material-symbols-sharp">
                  smart_toy
                </span>
                <span class="ml-2">{{ t('chatbots.title') }}</span>
                <span class="p-ink"></span>
              </a>
            </li>
            <li *ngIf="urlContainsUuid()">
              <a (click)="changePage('dashboard')"
                [ngClass]="{'text-blue-500 surface-100': router.url.includes('/dashboard'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/dashboard')}"
                class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                <span class="material-symbols-sharp">
                  home
                </span>
                <span class="ml-2">{{ t('dashboard.title') }}</span>
                <span class="p-ink"></span>
              </a>
            </li>
            <li *ngIf="urlContainsUuid()">
              <a (click)="changePage('chats')"
                [ngClass]="{'text-blue-500 surface-100': router.url.includes('/chats'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/chats')}"
                class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                <span class="material-symbols-sharp">
                  chat
                </span>
                <span class="ml-2">{{ t('chats.title') }}</span>
                <span class="p-ink"></span>
              </a>
            </li>
            <li *ngIf="urlContainsUuid()">
              <a pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup"
                class="no-underline p-ripple p-element flex p-3 align-items-center justify-content-between text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                <div class="flex justify-content-center align-items-center">
                  <span class="material-symbols-sharp">
                    school
                  </span>
                  <span class="ml-2">{{ t('sources.title') }}</span>
                </div>
                <span class="material-symbols-sharp text-3xl">
                  keyboard_arrow_down
                </span>
              </a>
              <ul [ngClass]="{'hidden': !router.url.includes('/sources')}" class="list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                <li>
                  <a (click)="changePage('sources', 'documents')" 
                  [ngClass]="{'text-blue-500 surface-100': router.url.includes('/sources#documents'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/sources#documents')}" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                    <span class="material-symbols-sharp">
                      text_snippet
                    </span>
                    <span class="ml-2">{{ t('sources.files') }}</span>
                  </a>
                </li>
                <li>
                  <a (click)="changePage('sources', 'drive')" 
                  [ngClass]="{'text-blue-500 surface-100': router.url.includes('/sources#drive'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/sources#drive')}" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                    <span class="material-symbols-sharp">
                      add_to_drive
                    </span>
                    <span class="ml-2">{{ t('sources.drive') }}</span>
                  </a>
                </li>
                <li>
                  <a (click)="changePage('sources', 'urls')" 
                  [ngClass]="{'text-blue-500 surface-100': router.url.includes('/sources#urls'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/sources#urls')}" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                    <span class="material-symbols-sharp">
                      language
                    </span>
                    <span class="ml-2">{{ t('sources.website') }}</span>
                  </a>
                </li>
                <li>
                  <a (click)="changePage('sources', 'q&a')" 
                  [ngClass]="{'text-blue-500 surface-100': router.url.includes('/sources#q&a'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/sources#q&a')}" class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                    <span class="material-symbols-sharp">
                      forum
                    </span>
                    <span class="ml-2">{{ t('sources.q&a') }}</span>
                  </a>
                </li>
              </ul>
            </li>
            <li *ngIf="urlContainsUuid()">
              <a (click)="changePage('leads')"
                [ngClass]="{'text-blue-500 surface-100': router.url.includes('/leads'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/leads')}"
                class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                <span class="material-symbols-sharp">
                  leaderboard
                </span>
                <span class="ml-2">{{ t('leads.title') }}</span>
                <span class="p-ink"></span>
              </a>
            </li>
            <li *ngIf="urlContainsUuid() && (userHasPluginAvailable || user?.admin)">
              <a (click)="changePage('plugins')"
                [ngClass]="{'text-blue-500 surface-100': router.url.includes('/plugins'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/plugins')}"
                class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                <span class="material-symbols-sharp">
                  extension
                </span>
                <span class="ml-2">{{ t('plugins.title') }}</span>
                <span class="p-ink"></span>
              </a>
            </li>
            <li *ngIf="urlContainsUuid()">
              <a pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup"
                class="no-underline p-ripple p-element flex p-3 align-items-center justify-content-between text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                <div class="flex justify-content-center align-items-center">
                  <span class="material-symbols-sharp">
                    settings
                  </span>
                  <span class="ml-2">{{ t('settings.title') }}</span>
                </div>
                <span class="material-symbols-sharp text-3xl">
                  keyboard_arrow_down
                </span>
              </a>
              <ul [ngClass]="{'hidden': !router.url.includes('/settings')}"
                class="list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                <li>
                  <a (click)="changePage('settings', 'general')"
                    [ngClass]="{'text-blue-500 surface-100': router.url.includes('/settings#general'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/settings#general')}"
                    class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                    <span class="material-symbols-sharp">
                      tune
                    </span>
                    <span class="ml-2">{{ t('settings.general') }}</span>
                  </a>
                </li>
                <li>
                  <a (click)="changePage('settings', 'lead')"
                    [ngClass]="{'text-blue-500 surface-100': router.url.includes('/settings#lead'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/settings#lead')}"
                    class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                    <span class="material-symbols-sharp">
                      leaderboard
                    </span>
                    <span class="ml-2">{{ t('leads.title') }}</span>
                  </a>
                </li>
                <li>
                  <a (click)="changePage('settings', 'chat-interface')"
                    [ngClass]="{'text-blue-500 surface-100': router.url.includes('/settings#chat-interface'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/settings#chat-interface')}"
                    class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                    <span class="material-symbols-sharp">
                      architecture
                    </span>
                    <span class="ml-2">{{ t('settings.chat_interface') }}</span>
                  </a>
                </li>
                <li>
                  <a (click)="changePage('settings', 'privacy')"
                    [ngClass]="{'text-blue-500 surface-100': router.url.includes('/settings#privacy'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/settings#privacy')}"
                    class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                    <span class="material-symbols-sharp">
                      policy
                    </span>
                    <span class="ml-2">{{ t('settings.privacy_policy') }}</span>
                  </a>
                </li>
                <li>
                  <a (click)="changePage('settings', 'security')"
                    [ngClass]="{'text-blue-500 surface-100': router.url.includes('/settings#security'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/settings#security')}"
                    class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                    <span class="material-symbols-sharp">
                      shield
                    </span>
                    <span class="ml-2">{{ t('settings.security') }}</span>
                  </a>
                </li>
              </ul>
            </li>
            <li *ngIf="urlContainsUuid()">
              <a (click)="changePage('embed')"
                [ngClass]="{'text-blue-500 surface-100': router.url.includes('/embed'), 'hover:text-900 hover:surface-100 cursor-pointer': !router.url.includes('/embed')}"
                class="no-underline p-ripple p-element flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                <span class="material-symbols-sharp">
                  link
                </span>
                <span class="ml-2">{{ t('embed_on_site.title') }}</span>
                <span class="p-ink"></span>
              </a>
            </li>
          </ul>
          <ul class="list-none p-3 m-0">
            <li *ngIf="urlContainsUuid()">
              <a (click)="openChat()" target="_blank"
                class="hover:text-900 hover:surface-100 cursor-pointer no-underline p-ripple p-element flex p-3 align-items-center text-600 font-medium border-round transition-colors transition-duration-150">
                <span class="material-symbols-sharp">
                  open_in_new
                </span>
                <span class="ml-2">{{ t('open_chat') }}</span>
                <span class="p-ink"></span>
              </a>
            </li>
          </ul>

          <div class="mt-auto">
            <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
            <a (click)="menu.toggle($event)" class="p-3 mx-3 mb-3 flex justify-content-between align-items-center cursor-pointer gap-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
              <div class="flex align-items-center">
                <p-avatar *ngIf="avatarLabel" [label]="avatarLabel" size="normal"
                  [style]="{ 'background-color': '#ece9fc', color: '#2a1261' }" shape="circle" />
                <img *ngIf="!avatarLabel" src="assets/images/user.png" width="28" class="mr-3 lg:mr-0">
                <p class="pl-3 m-0">
                  <b>{{ user.name | titlecase }} {{ user.surname | titlecase }}</b>
                  <br>
                  <!-- TODO: con una email lunga va fuori dal container -->
                  <span class="text-sm text-500">{{ user.email }}</span>
                  <p-badge *ngIf="user.admin" styleClass="mt-2" [value]="'Account admin'" severity="warning" />
                </p>
              </div>
              <span class="material-symbols-sharp text-3xl">
                keyboard_arrow_right
              </span>
            </a>
            <p-tieredMenu #menu [model]="profileOptions" [popup]="true" />
          </div>
        </div>

      </div>
    </ng-template>
  </p-sidebar>
</ng-container>