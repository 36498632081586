import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { AIModels } from 'src/app/app.config';
import { SelectItemGroup } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private models$: Observable<SelectItemGroup[]> | null = null;
  private assistants$: Observable<any[]> | null = null;

  private assistants: any[] = [];

  constructor(private apiService: ApiService) {}

  /**
   * Sanitizes a given model by recursively traversing its properties and converting
   * empty string values to `null`. This function handles both arrays and objects.
   *
   * @param model - The model to be sanitized. It can be an object or an array.
   * @returns The sanitized model with empty string values replaced by `null`.
   */
  public static sanitizePyaload(model: any): any {
    function sanitize(obj: any): any {
      // Handle arrays
      if (Array.isArray(obj)) {
        return obj.map(sanitize);
      }

      // Handle objects
      if (obj !== null && typeof obj === 'object') {
        const sanitizedObj: any = {};
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (obj[key] === '') {
              sanitizedObj[key] = null;
            } else {
              sanitizedObj[key] = sanitize(obj[key]);
            }
          }
        }
        return sanitizedObj;
      }

      // Return the value if it's neither an object nor an array
      return obj;
    }

    return sanitize(model);
  }

  /**
   * Retrieves the list of assistants.
   * 
   * This method returns an observable that emits the list of assistants. If the list of assistants
   * has already been fetched, it returns the cached observable. If the list of assistants is already
   * available in memory, it returns an observable of the in-memory list. Otherwise, it makes an API
   * call to fetch the list of assistants and caches the result in an observable.
   * 
   * @returns {Observable<any>} An observable that emits the list of assistants.
   * 
   * @throws {Error} If the API call fails, an error is thrown with the error message(s) from the response.
   */
  getAssistants(): Observable<any> {
    if (this.assistants$) {
      // If the observable is already present, return it
      return this.assistants$;
    } else if (this.assistants.length > 0) {
      return of(this.assistants);
    } else {
      // Make the API call once and store the result in a shared observable
      this.assistants$ = this.apiService.getChatBots().pipe(
        tap((response) => {
          if (response.success) {
            this.assistants = response.data;
            console.log(this.assistants);
          } else {
            throw new Error(response.error.join(", "));
          }
        }),
        map(() => this.assistants),
        shareReplay(1) // Shares the last value among all subscriptions
      );
      return this.assistants$;
    }
  }

  /**
   * Retrieves a list of AI models grouped by company.
   * 
   * This method returns an observable of `SelectItemGroup[]`. If the models have already been loaded,
   * it returns an immediate observable with the cached data. Otherwise, it makes an API call to fetch
   * the models and caches the result in a shared observable.
   * 
   * @returns {Observable<SelectItemGroup[]>} An observable that emits the list of AI models grouped by company.
   */
  getModels(): Observable<SelectItemGroup[]> {
    if (this.models$) {
      // If the observable is already present, return it
      return this.models$;
    } else if (AIModels.length > 0) {
      // If AIModels has already been loaded, return an immediate observable
      return of(AIModels);
    } else {
      // Make the API call once and store the result in a shared observable
      this.models$ = this.apiService.getModels().pipe(
        tap((response) => {
          if (response.success) {
            response.data.forEach((company: any) => {
              let obj: SelectItemGroup = { label: company.company, items: [] };
              company.models.forEach((model: any) => {
                obj.items.push({ label: model.name, value: model.value });
              });
              AIModels.push(obj);
            });
          }
        }), 
        map(() => AIModels), // Mappa la risposta in AIModels
        shareReplay(1) // Condivide l'ultimo valore (AIModels) tra tutte le iscrizioni
      );
      return this.models$;
    }
  }

  /**
   * Extracts and returns the model name from a given string.
   * 
   * The input string is expected to follow the format `company:<companyName>/model:<modelName>`.
   * If the input string contains both `company:` and `/model:`, the method extracts and returns the model name.
   * If the input string does not match the expected format, the method returns the original input string.
   * 
   * @param model - The input string containing the model information.
   * @returns The extracted model name if the input string matches the expected format, otherwise returns 'Undefined'.
   */
  getModelName(model: string): string {
    if (model.includes('company:') && model.includes('/model:')) {
      const match = model.match(/company:[^/]+\/model:(.+)/);
      return match ? match[1] : 'Undefined';
    }
    return model;
  }

  /**
   * Retrieves the company name from a given model string or from a list of models.
   *
   * @param model - The model string which may contain the company and model information, or null.
   * @param models - An array of model groups, where each group contains a label and a list of items.
   * @returns The company name extracted from the model string or found in the models list.
   *
   * If the model string is null, it returns 'OpenAI'.
   * If the model string contains 'company:' and '/model:', it extracts and returns the company name.
   * Otherwise, it searches through the models list to find the company name associated with the model.
   */
  getCompanyFromModel(model: string | null, models: any): string {
    if (model === null)
      return 'OpenAI';

    if (model.includes('company:') && model.includes('/model:')) {
      const match = model.match(/company:([^/]+)\/model:([^/]+)/);
      return match ? match[1] : '';
    }

    let company = '';
    models.forEach((group: any) => {
      group.items.forEach((item: any) => {
        if (item.value === model)
          company = group.label;
      });
    });
    return company;
  }

  hexColorValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isValidHex = /^#[0-9A-F]{6}$/i.test(control.value);
      return isValidHex ? null : { invalidHex: true };
    };
  }
}
