import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../storage/storage.service';
import { JwtPayload, jwtDecode } from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private router: Router, private storageService: StorageService) { }

  /**
   * Determines if the route can be activated based on the presence and validity of a JWT token.
   * 
   * @param route - The route snapshot that contains the future route information.
   * @param state - The router state snapshot that contains the future router state information.
   * @returns A boolean indicating whether the route can be activated.
   * 
   * This method checks if a JWT token is present in the storage service. If a token is found,
   * it decodes the token to check its expiration date. If the token is expired, it navigates
   * to the login page and returns false. If the token is valid, it returns true. If no token
   * is found, it also navigates to the login page and returns false.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = this.storageService.get('user')?.token;
    if (token) {
      const token_decoded: JwtPayload = jwtDecode(token);
      if (Date.now() > ((token_decoded.exp || 0) * 1000)) {
        this.router.navigate(['/auth/login']);
        return false;
      }
      return true;
    }
    this.router.navigate(['/auth/login']);
    return false;
  }
}
